import { receivePunchoutLoginError } from '../reducers/storefront.reducer';
import { I18nService } from '../utils/i18n.service';
import { hasProp } from '../utils/util';
import { NewRelic } from './newrelic.service';
import { StoreDataService } from './store-data.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { CognitoService } from '@app/shared/services/cognito.service';
import { getUser, isLoggedUser } from '@app/user/reducers/user.reducer';
import { firstValueFrom, identity, MonoTypeOperatorFunction } from 'rxjs';
import { first, skip } from 'rxjs/operators';

/** Punchout guard service. Handles punchout authentication. */
@Injectable({ providedIn: 'root' })
export class PunchOutGuardService {
	constructor(
		private readonly cognito: CognitoService,
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly newRelic: NewRelic,
		public readonly storeData: StoreDataService,
		private readonly i18n: I18nService,
	) {}

	/** Activate route. */
	async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree> {
		const token = route.queryParamMap.get('t');
		const selectUser = (
			operator: MonoTypeOperatorFunction<api.UserDto | undefined> = identity,
		): Promise<api.UserDto | undefined> =>
			firstValueFrom(this.store.select(getUser).pipe(operator, first()));

		try {
			// Log out any active session.
			if (isLoggedUser(await selectUser())) await this.cognito.logout();
			// Attempt cXML authentication.
			await this.cognito.loginWithCxmlToken(token || '');
			// Listen for updated user to see if login succeeded.
			const user = await selectUser(skip(1));
			if (!isLoggedUser(user)) throw new Error('Authentication failed');
			// Login success, redirect to root and let main component handle final redirection.
			// TODO: Move logic from main component into a guard e.g. content guard.
			return this.router.createUrlTree(['/']);
		} catch (err: unknown) {
			this.newRelic.noticeError(err);
			this.store.dispatch(
				receivePunchoutLoginError(hasProp(err, 'code') ? (err.code as string) : 'ERROR'),
			);
			return this.router.createUrlTree(['/']);
		}
	}
}

@let content = content$ | async | foldSuccess;
@if (content && content.footerStyle !== 'none') {
	<footer>
		@switch (content.footerStyle) {
			<!-- Default footer -->
			@case ('default') {
				<div class="container">
					<div class="footer" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="40px">
						<!-- Left half -->
						<div
							class="left-half"
							fxFlex="60%"
							fxFlex.lt-lg="50%"
							fxFlex.lt-md="100%"
							fxLayout="row"
							fxLayout.lt-sm="column"
						>
							@if (content.showLogo) {
								<a class="logo" [routerLink]="['']">
									<img
										[gResponsiveSrc]="logoUrl$ | async"
										width="150"
										height="45"
										i18n-alt="@@StoreLogoAlt"
										alt="Store logo"
										loading="lazy"
									/>
								</a>
							}
							<div fxFlex="80%">
								@if (content.showDescription) {
									<p [innerHTML]="parseDescription(content.description)"></p>
								}
							</div>
							@if (viewport.isMediumOrLarger$ | async) {
								<div fxFlex="130px" fxFlex.lt-lg="50px"></div>
							}
						</div>

						<!-- Right half -->
						<div
							class="right-half"
							fxFlex="40%"
							fxFlex.lt-lg="50%"
							fxFlex.lt-md="100%"
							fxLayout="row wrap"
						>
							<div class="products" fxFlex="40%" fxFlex.lt-sm="60%">
								@if (content.showCategories) {
									<div>
										<h3 i18n="@@FooterProductsHeader">Products</h3>
										<ul>
											@for (category of categories$ | async; track category.id) {
												<li>
													<a [routerLink]="['/category/', category.id, category.slug]">
														{{ category.locale?.name | getText }}
													</a>
												</li>
											}
										</ul>
									</div>
								}
							</div>
							<div fxFlex="60%" fxFlex.lt-sm="40%" fxLayout="row" fxLayout.lt-sm="column">
								<div class="support" fxFlex="50%">
									@if (content.showPageLinks) {
										<h3 i18n="@@FooterSupportHeader">Support</h3>
										<ul>
											@for (
												item of pagesAlphabetically(content, pages$ | async | foldSuccess);
												track item.key
											) {
												<li>
													<a [routerLink]="['/page', item.key]">{{ item.content.title }}</a>
												</li>
											}
										</ul>
									}
								</div>
								<div class="social-media" fxFlex="50%">
									@if (content.showSomeLinks) {
										<h3 i18n="@@FooterSocialMediaHeader">Social Media</h3>
										<ul>
											@for (item of content.someLinks; track $index) {
												<li>
													@if (icons[item.someLinkIcon]) {
														<g-icon [icon]="icons[item.someLinkIcon]" fixedWidth />
													} @else {
														<span class="icon-spacer"></span>
													}
													<a [attr.href]="item.someLinkUrl">{{ item.someLinkText }}</a>
												</li>
											}
										</ul>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			<!-- Custom footer -->
			@case ('custom') {
				<div class="container">
					<div class="custom-footer">
						<div [innerHTML]="content.html | trustedHtml"></div>
					</div>
				</div>
			}
			<!-- Remote footer -->
			@case ('remote') {
				<g-custom-html-block-v2 key="remote-footer" />
			}
		}
	</footer>
}

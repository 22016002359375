import { cartItemsEqual } from '../reducers/persisted-list.reducer';
import { Injectable } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import {
	State as CheckoutState,
	getState as getCheckoutState,
	setState as setCheckoutState,
	setPersistedStateLoaded as setPersistedCheckoutStateLoaded,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { getProductsById } from '@app/product/reducers/product.reducer';
import { getFlag } from '@app/shared/utils/flag';
import {
	firstTruthy,
	getDebug,
	isArrayEqual,
	isPlainObjectEqual,
	once,
	pick,
	tryParseJson,
} from '@app/shared/utils/util';
import { getIsUserChecked, getUser } from '@app/user/reducers/user.reducer';
import { FeatureFlags } from '@src/types/environment';
import { EMPTY, firstValueFrom, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	mergeMap,
	startWith,
	switchMap,
} from 'rxjs/operators';

// Version for data to persist. If for example fields are changed, increment these.
export const CHECKOUT_STATE_VERSION = 4;
export const STORE_PERSIST_DEBOUNCE_TIME = 500;

export const CC_ANONYMOUS_USER_STORAGE_KEY = 'cc-anonymous-user';
export enum StorageKey {
	Checkout = 'checkout',
}

const CHECKOUT_PLAIN_VALUES = [
	// TODO: Remove after checkout v2.
	'currentCheckoutStep',
	'isOnMobileOrderContents',
	// END TODO
	'paymentMethod',
	'shippingMethod',
	'referenceCode',
	'purchaseOrderNumber',
	'orderMessage',
	'userDefinedDeliveryDate',
	'discountCode',
	'newOrderAccessToken',
	'isCxmlPunchout',
] as const satisfies (keyof CheckoutState)[];

const CHECKOUT_PERSISTED_FIELDS = [
	'shippingAddress',
	'billingAddress',
	'userInfo',
	...CHECKOUT_PLAIN_VALUES,
] as const satisfies (keyof CheckoutState)[];

export const storageMapping = {
	[StorageKey.Checkout]: {
		version: CHECKOUT_STATE_VERSION,
		getState: getCheckoutState,
		setState: setCheckoutState,
		setIsLoaded: setPersistedCheckoutStateLoaded,
		areStatesEqual: (s1: CheckoutState, s2: CheckoutState): boolean =>
			isArrayEqual(s1.items, s2.items, cartItemsEqual) &&
			isPlainObjectEqual(s1.shippingAddress, s2.shippingAddress, ['address_type']) &&
			isPlainObjectEqual(s1.billingAddress, s2.billingAddress, ['address_type']) &&
			isPlainObjectEqual(s1.userInfo, s2.userInfo) &&
			isPlainObjectEqual(pick(s1, CHECKOUT_PLAIN_VALUES), pick(s2, CHECKOUT_PLAIN_VALUES)),
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		serializeState: (state: Partial<CheckoutState>) => ({
			...pick(state, CHECKOUT_PERSISTED_FIELDS),
			items: state?.items?.map?.((item) => ({
				...item,

				product: {
					// Store minimal product information to save space.
					...pick(item.product, ['id', 'updated_at', 'properties', 'metadata', 'type', 'categories']),
					locale: pick(item.product.locale, ['product_name']),
					images: { master: { fitIn_80x80: item.product.images?.master?.fitIn_80x80 } },
				},
			})),
		}),
	},
};

export interface ShoppingCartStatus {
	productUuid: string;
	lastUpdated: string;
	isPersisted: boolean;
}

export type ShoppingCartStates = Record<string, ShoppingCartStatus[]>;

interface StorageType {
	[StorageKey.Checkout]: {
		storage: ReturnType<(typeof storageMapping)[StorageKey.Checkout]['serializeState']>;
		state: ReturnType<(typeof storageMapping)[StorageKey.Checkout]['getState']>;
	};
}

/**
 * Service to persist and retrieve data from local storage.
 */
@Injectable({ providedIn: 'root' })
export class StorePersistService {
	private readonly debug = getDebug('StorePersistService');
	// eslint-disable-next-line unicorn/consistent-function-scoping
	readonly init = once(() => Promise.all(Object.values(StorageKey).map(this.initializeStorage)));

	private readonly localStorage = localStorage;
	private readonly sessionStorage = sessionStorage;
	/* The StorageEvents are dispatched to other tabs by default, let's use observable for the origin tab */
	private readonly storageUpdates$ = new Subject<StorageEvent>();

	constructor(private readonly store: Store<RootReducer.State>) {}

	/**
	 * Update Redux state to local storage.
	 */
	updateStoredState<T extends StorageKey>(storageKey: T, state: StorageType[T]['state']): void {
		const { serializeState, version } = storageMapping[storageKey];
		const persistVersionKey = `${storageKey}PersistVersion`;
		const storageState = serializeState(structuredClone(state));
		this.debug('updateStoredState', storageKey, storageState);
		this.localStorage.setItem(storageKey, JSON.stringify(storageState));
		this.localStorage.setItem(persistVersionKey, JSON.stringify(version));
		/* istanbul ignore next */
		if (storageKey === StorageKey.Checkout) void this.updateShoppingCartStatus(state);
	}

	/**
	 * Retrieves the stored state from the local storage based on the provided storage key.
	 * @param storageKey The key used to identify the stored state.
	 * @returns The stored state if it exists and matches the version, otherwise null.
	 */
	getStoreState<T extends StorageKey>(storageKey: T): StorageType[T]['storage'] | undefined {
		const persistVersionKey = `${storageKey}PersistVersion`;
		const persistVersion = JSON.parse(this.localStorage.getItem(persistVersionKey) || '0') as number;
		const { version } = storageMapping[storageKey];
		// Ignore data if version doesn't match.
		if (persistVersion !== version) return undefined;

		return tryParseJson<StorageType[T]['storage']>(this.localStorage.getItem(storageKey));
	}

	/** TODO: Refactor */
	getShoppingCartStatus(activeUserId?: string): ShoppingCartStatus[] {
		const userId = typeof activeUserId === 'string' ? activeUserId : 'null';
		const storageStateData = this.localStorage.getItem('shoppingCartStates') || '';
		const storedState =
			storageStateData.length > 0 ? (JSON.parse(storageStateData) as ShoppingCartStates) : {};
		return storedState[userId] || [];
	}

	/** TODO: Refactor */
	setShoppingCartStatus(
		activeUserId: string | boolean | undefined,
		cartState: ShoppingCartStatus[],
	): void {
		const userId = typeof activeUserId === 'string' ? activeUserId : 'null';
		const storageStateData = this.localStorage.getItem('shoppingCartStates');
		const currentStatus =
			typeof storageStateData === 'string' && storageStateData.length > 0
				? (JSON.parse(storageStateData) as ShoppingCartStates)
				: {};
		const updatedStatus = { ...currentStatus };
		if (!Array.isArray(updatedStatus[userId])) updatedStatus[userId] = [];
		updatedStatus[userId] = [...cartState];
		this.localStorage.setItem('shoppingCartStates', JSON.stringify(updatedStatus));
	}

	/**
	 * Retrieves the value of a feature flag from the local storage or environment config.
	 * For use in non DI contexts like Module decorators, use {@link getFlag} instead.
	 * @param key - The key of the feature flag to retrieve.
	 * @returns The value of the feature flag.
	 */
	getFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K] {
		return getFlag(key);
	}

	/**
	 * Toggles the value of a feature flag in the local storage.
	 * @param key - The key of the feature flag to toggle.
	 * @returns The new value of the feature flag.
	 */
	toggleFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K] {
		const current = this.getFlag(key);
		localStorage.setItem(key, `${(!current).toString()}`);
		return !current;
	}

	/**
	 * @param key storage key to watch
	 * @returns An observable that emits the value of the storage key, and when it changes.
	 */
	watchStorageKey<T extends string>(key: T): Observable<string | null> {
		return merge(fromEvent<StorageEvent>(window, 'storage'), this.storageUpdates$).pipe(
			mergeMap((event) => (event.key === key ? of(event.newValue) : EMPTY)),
			startWith(this.localStorage.getItem(key)),
		);
	}

	/**
	 * Sets a key in the localStorage and triggers a storage event for the current tab.
	 * @param key The key to set.
	 * @param value The value to set.
	 */
	setStorageKey<T extends string>(key: T, value: string): void {
		this.localStorage.setItem(key, value);
		this.storageUpdates$.next(
			new StorageEvent('storage', {
				key,
				newValue: value,
				oldValue: this.localStorage.getItem(key),
			}),
		);
	}

	/**
	 * Removes a key from the localStorage and triggers a storage event for the current tab.
	 * @param key The key to remove.
	 */
	removeStorageKey<T extends string>(key: T): void {
		this.localStorage.removeItem(key);
		this.storageUpdates$.next(
			new StorageEvent('storage', {
				key,
				oldValue: this.localStorage.getItem(key),
			}),
		);
	}

	private readonly initializeStorage = async <T extends StorageKey>(storageKey: T) => {
		let savedState = this.getStoreState(storageKey);
		const { getState, areStatesEqual, setIsLoaded } = storageMapping[storageKey];

		// If stored state was from a punchout session but session has ended, discard it.
		if (savedState?.isCxmlPunchout && !this.sessionStorage.getItem('cxml_token')) {
			savedState = undefined;
		}

		// Wait until auth has been initialized.
		await firstValueFrom(this.store.select(getIsUserChecked).pipe(firstTruthy));

		// Initial sync from localstorage to store on load
		if (savedState) await this.appendProductDataToState(storageKey, structuredClone(savedState));
		this.store.dispatch(setIsLoaded(true));

		// Sync from localstorage to store when localstorage changes (e.g. changes in other tab)
		/* istanbul ignore next */
		fromEvent<StorageEvent>(window, 'storage')
			.pipe(
				filter((event) => event.key !== storageKey),
				switchMap((event) =>
					this.appendProductDataToState(
						storageKey,
						(typeof event.newValue === 'string' ? tryParseJson(event.newValue) : event.newValue) ??
							undefined,
					),
				),
			)
			.subscribe();

		// Sync from Redux store to local storage.
		this.store
			.select(getState)
			.pipe(
				distinctUntilChanged(areStatesEqual),
				debounceTime(STORE_PERSIST_DEBOUNCE_TIME),
				this.debug.observe(storageKey),
			)
			.subscribe((cartState) => this.updateStoredState(storageKey, cartState));
	};

	/**
	 * Appends product data to the state based on the given storage key.
	 * @param storageKey The storage key.
	 * @param state The state to append the product data to.
	 * @returns A promise that resolves when the product data has been appended to the state.
	 */
	private async appendProductDataToState<T extends StorageKey>(
		storageKey: T,
		state: StorageType[T]['storage'] | undefined,
	) {
		const items = state?.items || [];
		const productIds = new Set(
			items.map((item) => item?.product?.id).filter((id): id is number => !!id),
		);
		// istanbul ignore if
		if (productIds.size === 0) return;

		const products = await firstValueFrom(
			this.store.select(getProductsById(productIds)).pipe(firstTruthy),
		);

		const newState = {
			...state,
			items: items.map((item) => {
				const product = products?.find((p) => p.id === item.product.id);
				return {
					...item,
					product: (product ? structuredClone(product) : item.product) as api.CartItemProductDto,
				};
			}),
		};

		this.store.dispatch(storageMapping[storageKey].setState(newState));
	}

	/**
	 * Updates the shopping cart status in the storage.
	 * @param state The state of the shopping cart.
	 * @returns A promise that resolves when the shopping cart status is updated.
	 */
	private async updateShoppingCartStatus<T extends StorageKey>(
		state: StorageType[T]['state'],
	): Promise<void> {
		const user = await firstValueFrom(this.store.select(getUser));
		const userId = user?.id;
		const currentStatus = this.getShoppingCartStatus(userId);
		const items = state.items;
		const missingItems = items.filter(
			(item) => !currentStatus?.find((status) => status.productUuid === item.id),
		);
		if (missingItems.length === 0) return;
		const updatedStatus = [
			...currentStatus,
			...missingItems.map((item) => ({
				productUuid: item.id,
				lastUpdated: new Date().toISOString(),
				isPersisted: false,
			})),
		];
		this.setShoppingCartStatus(userId, updatedStatus);
	}
}

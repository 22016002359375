// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Users */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Update user in Cas and Cognito.
 * 
 * @param {string} id User ID or "me"
 * @param {module:types.UserUpdateRequestDto} userUpdateRequest User update details
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.UserDto>} Updated user
 */
export function update(id: string, userUpdateRequest: api.UserUpdateRequestDto, options?: UpdateOptions): Promise<api.Response<api.UserDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      userUpdateRequest
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Update user in Cas and Cognito.
 * 
 * @param {number} customerId Customer ID
 * @param {string} id User ID
 * @param {module:types.UserUpdateRequestDto} userUpdateRequest User update details
 * @return {Promise<module:types.UserDto>} Updated user data
 */
export function updateByCustomerId(customerId: number, id: string, userUpdateRequest: api.UserUpdateRequestDto): Promise<api.Response<api.UserDto>> {
  const parameters: api.OperationParamGroups = {
    path: {
      customerId,
      id
    },
    body: {
      userUpdateRequest
    }
  };
  return gateway.request(updateByCustomerIdOperation, parameters);
}

/**
 * Search users in store or customer's user pool
 * 
 * @param {string} searchTerm Search term for user filtering
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.UserDto[]>} Array of users
 */
export function searchInCustomerOrService(searchTerm: string, options?: SearchInCustomerOrServiceOptions): Promise<api.Response<api.UserDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      searchTerm
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(searchInCustomerOrServiceOperation, parameters);
}

/**
 * Add a user to the super admin group
 * 
 * @param {string} userId ID of the user for which the super admin status will be granted
 * @return {Promise<module:types.UserCasGroupDto[]>} Array of user's groups after operation
 */
export function grantSuperAdmin(userId: string): Promise<api.Response<api.UserCasGroupDto[]>> {
  const parameters: api.OperationParamGroups = {
    path: {
      userId
    }
  };
  return gateway.request(grantSuperAdminOperation, parameters);
}

/**
 * Remove a user from the super admin group
 * 
 * @param {string} userId ID of the user for which the super admin status will be revoked
 * @return {Promise<module:types.UserCasGroupDto[]>} Array of user's groups after operation
 */
export function revokeSuperAdmin(userId: string): Promise<api.Response<api.UserCasGroupDto[]>> {
  const parameters: api.OperationParamGroups = {
    path: {
      userId
    }
  };
  return gateway.request(revokeSuperAdminOperation, parameters);
}

export interface UpdateOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface SearchInCustomerOrServiceOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const updateOperation: api.OperationInfo = {
  path: '/v2/users/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateByCustomerIdOperation: api.OperationInfo = {
  path: '/v2/users/{id}/{customerId}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const searchInCustomerOrServiceOperation: api.OperationInfo = {
  path: '/v2/users/search-users/in-customer-or-service/{searchTerm}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const grantSuperAdminOperation: api.OperationInfo = {
  path: '/v2/users/grant-super-admin/{userId}',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const revokeSuperAdminOperation: api.OperationInfo = {
  path: '/v2/users/revoke-super-admin/{userId}',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

import { AsyncPipe } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	Pipe,
	PipeTransform,
	ViewChild,
} from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { ContentV2Types, getContentByKey } from '@app/core/reducers/content.reducer';
import { TrustedHtmlPipe } from '@app/shared/pipes/trusted-html.pipe';
import { NewRelic } from '@app/shared/services/newrelic.service';
import { FoldSuccessPipe } from '@app/shared/utils/template-helpers';
import { ApiSelector, pluckSuccessData } from '@granodigital/grano-remote-data';
import { filter, first } from 'rxjs';

/** Unescape HTML entities */
@Pipe({ name: 'unescapeHtml', standalone: true })
export class UnescapeHtmlPipe implements PipeTransform {
	private readonly decodingMap: Record<string, string> = {
		'&amp;': '&',
		'&quot;': '"',
		'&apos;': "'",
		'&lt;': '<',
		'&gt;': '>',
	};
	/** Unescape HTML entities */
	transform(s: string): string {
		return s.replaceAll(/&[a-z]+;/g, (e) => this.decodingMap[e] || e);
	}
}

/** A component for rendering custom blocks of trusted HTML content. */
@Component({
	selector: 'g-custom-html-block-v2',
	templateUrl: './custom-html-block-v2.component.html',
	styleUrls: ['./custom-html-block-v2.component.scss'],
	standalone: true,
	imports: [AsyncPipe, FoldSuccessPipe, UnescapeHtmlPipe, TrustedHtmlPipe],
})
export class CustomHtmlBlockV2Component implements AfterViewInit {
	/** Set the key for the custom HTML block. */
	@Input({ required: true }) set key(key: string) {
		this.matter$ = this.store.select(getContentByKey<ContentV2Types>(key));
	}
	@ViewChild('iframe') readonly iframe!: ElementRef<HTMLIFrameElement>;
	matter$!: ApiSelector<ContentV2Types>;

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly newRelic: NewRelic,
	) {}

	/** Set the sandbox attribute on the iframe element. */
	ngAfterViewInit(): void {
		this.matter$
			.pipe(
				pluckSuccessData,
				first(),
				filter((matter) => matter.type === 'remote-content'),
			)
			.subscribe((matter) => {
				try {
					if (matter.content.sandbox)
						this.iframe.nativeElement.sandbox.add(...matter.content.sandbox.split(' '));
					// Update the iframe height when the content is loaded or the window is resized.
					this.iframe.nativeElement.addEventListener('load', () => this.updateIframeHeight());
					globalThis.addEventListener('resize', () => this.updateIframeHeight(), { passive: true });
				} catch (err) {
					this.newRelic.noticeError(err);
				}
			});
	}

	/** Get the height of the iframe content. */
	private updateIframeHeight(): void {
		const iframeElem = this.iframe.nativeElement;
		const scrollHeight = iframeElem.contentWindow?.document?.body?.scrollHeight;
		if (scrollHeight) {
			iframeElem.height = `${scrollHeight}px`;
			iframeElem.style.overflowY = 'hidden';
			iframeElem.scrolling = 'no'; // Overflow hidden does not work in most browsers to this day.
		} else {
			// If height is not available, set the height to 100% of the viewport height and allow scrolling.
			iframeElem.height = '100dvh';
			iframeElem.style.overflowY = 'auto';
			iframeElem.scrolling = 'auto';
		}
	}
}

import type { CartItemError } from '../services/cart-item-errors';
import { CreateOptions } from '@app/api/Order';
import { GetPriceForProductOptions, getPriceForProduct } from '@app/api/Price';
import { StartPreflightOptions } from '@app/api/Upload';
import * as ApiOrder from '@app/api/action/Order';
import * as ApiPrice from '@app/api/action/Price';
import * as ApiShippingMethod from '@app/api/action/ShippingMethod';
import * as ApiUpload from '@app/api/action/Upload';
import { RootReducer } from '@app/app.reducers';
import { Step } from '@app/core/models/step';
import { ProductSource } from '@app/product/product-types';
import { ProductFlowPage } from '@app/product/reducers/product-flow.reducer';
import { isApiActionSuccess, parseApiActionError } from '@app/shared/utils/api-utils';
import { setName } from '@app/shared/utils/name-helper';
import { omit } from '@app/shared/utils/util';
import type { FileItem } from '@app/upload/upload.component';
import { AddressFormModel, AddressModel } from '@app/user/reducers/address-book.reducer';
import type { Editor } from '@aurigma/design-editor-iframe';
import {
	ApiAction,
	ApiError,
	Failure,
	Initialized,
	Pending,
	RemoteData,
	Success,
	foldSuccess,
} from '@granodigital/grano-remote-data';
import { format, isValid } from 'date-fns';
import { createSelector } from 'reselect';

//#region Helpers

export const ONLINE_SHIPPING_METHOD_SLUG = 'onlineDelivery';

const PREFLIGHT_START_FAILED_REPORT: api.PrintfileReportDto = {
	is_printable: false,
	error_count: 1,
	errors: [
		{
			level: 'error',
			message: {
				en: $localize`:@@PreflightStartFailed:Failed to check file, try to upload it again.`,
			},
			error_code: 'ERROR_CRITICAL_FAILURE',
		},
	],
};

/** Update printfile queue without mutating it. */
function removePrintfileFromQueue(state: State, key: string) {
	let preflightQueue = state.preflightQueue;
	preflightQueue = new Map(preflightQueue);
	preflightQueue.delete(key);
	return preflightQueue;
}

const deliveryDateCache = new Map<`${string} - ${number}`, boolean>();

/** Check if the item is a cart item with product data. */
export function isProductLoaded(product: api.ProductDetailsDto): product is api.CartItemProductDto {
	return !!product.metadata;
}

//#endregion

//#region Types

/**
 * A Cart Item usually contains full product data, but in some cases it might fail to load.
 * For example if the item is restored from local storage and the product is no longer available.
 * Hence why it's a partial type.
 */
export type CartItem = DeepPartial<api.CartItemWithProductDto> & api.CartItemDto;
/** A cart product or a product from an order. */
export type CartOrOrderProduct = CartItem['product'] | api.ProductDto;

export enum ItemSource {
	ShoppingCart = 'ShoppingCart',
	MyProducts = 'MyProducts',
}

export interface CheckoutStep extends Step {
	key: CheckoutStepKey;
}

export enum CheckoutStepNumber {
	Login = 1,
	Shipping = 2,
	Billing = 3,
	Payment = 4,
}

export enum CheckoutStepKey {
	Login = 'login',
	Shipping = 'shipping',
	Billing = 'billing',
	Summary = 'summary',
}

export enum PaymentMethod {
	Invoice = 'invoice',
	OnlineBank = 'onlineBank',
}
type _VerifyPaymentMethodEnum = VerifyExtends<PaymentMethod, api.OrderDto['metadata']['billing_method']>;

export type OnlinePaymentMethodGroup = NonNullable<
	NonNullable<CreateOptions['body']>['metadata']['online_payment_method']
>;

// TODO: refactor checkout step handling to use step names instead of numbers
export const stepNumToKeyMap = new Map<CheckoutStepNumber, CheckoutStepKey>([
	[CheckoutStepNumber.Login, CheckoutStepKey.Login],
	[CheckoutStepNumber.Shipping, CheckoutStepKey.Shipping],
	[CheckoutStepNumber.Billing, CheckoutStepKey.Billing],
	[CheckoutStepNumber.Payment, CheckoutStepKey.Summary],
]);

export const stepKeyToNumMap = new Map([...stepNumToKeyMap.entries()].map(([num, key]) => [key, num]));

export interface ProductEditorChanges extends api.CartItemOptionsEditorChangesDto {
	schemaForm?: Record<string, string | number | Editor.IUserInfo>;
	userChanges?: Editor.IUserInfo;
}

export interface DetailsData {
	workTitle?: string;
}

export interface WorkflowSelectorData {
	key: ProductFlowPage;
	steps: ProductFlowPage[];
}

export interface TemplateSelectorData {
	selectedTemplates?: string[];
}

export interface ProductEditorData {
	result: Editor.IFinishDesignResult;
	scaledProofImages?: string[];
	proofImageThumbnail?: string;
}

export interface ProductFlowDesignServiceData {
	form: { description: string; email: string };
	files: FileItem[];
}

export interface ValidateDesignData {
	isDesignValidated?: boolean;
}

export interface UploadMaterialData {
	printfile?: api.PrintfileDto;
}

export type ProductFlowStepData =
	| DetailsData
	| WorkflowSelectorData
	| TemplateSelectorData
	| ProductEditorData
	| ProductFlowDesignServiceData
	| ValidateDesignData
	| UploadMaterialData;

export interface ProductFlowData
	extends ValidateDesignData,
		Pick<WorkflowSelectorData, 'steps'>,
		Pick<ProductEditorData, 'scaledProofImages' | 'proofImageThumbnail'> {
	selectedWorkflow?: WorkflowSelectorData;
	designService?: ProductFlowDesignServiceData;
	productSource?: ProductSource;
	productOptionsSavedAt?: number;
	proofImageThumbnail?: string;
}

export interface NewOrder {
	id?: number;
	isProcessing: boolean;
	isSuccess: boolean;
	isError: boolean;
	content?: api.OrderDto;
	errorTrace?: string;
}

export interface FetchedOrder {
	isSuccess: boolean;
	isError: boolean;
	content?: api.OrderDto;
}

type OrderAddressType = keyof api.OrderAddressesDto;

export interface QuoteFormSubmit {
	isProcessing: boolean;
	isSuccess: boolean;
	isError: boolean;
}

export interface OrderFormSubmit {
	isProcessing: boolean;
	isSuccess: boolean;
	isError: boolean;
}

export type BillingPrices = Record<
	string,
	{
		name?: {
			en: string;
			fi: string;
		};
		price: string;
		priceWithoutTax: string;
		key?: string;
	}
>;

// TODO: Move to a DTO.
export interface ShippingPrice {
	key: string;
	range: string;
	price: string;
	priceWithoutTax: string;
}

export interface ShippingPricesError {
	message?: string;
	trace?: string;
}

export type AdditionalPrices = Partial<
	Record<NonNullable<api.AdditionalPrice['type']>, Record<string, string>>
>;

export interface TaxRow {
	percentage: string;
	amount: string;
}

export interface UserInfo {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
}

// TODO: Move to a DTO.
export type DeliveryDates = Record<
	string,
	Record<
		string,
		{
			withoutFixMin: string;
			withoutFixMax: string;
			withFixMin: string;
			withFixMax: string;
		}
	>
>;

export enum ResetCheckoutReason {
	ReadyToPay = 'readyToPay',
	CartCleared = 'cartCleared',
	Logout = 'logout',
}
export interface SummaryItemDto {
	id: number;
	uuid: string;
	work_title?: string;
	product_flow_data?: api.CartItemProductFlowDataDto;
	// Looks like crap but this is for the GetQuantityUnitPipe
	// TODO: ED-2462: Remove this when we have a better solution.
	product?: api.ProductDto;
	options?: {
		printfile_key?: string;
		editor_state_id?: string;
		order_quantity?: string;
		quantity?: number;
	};
	price?: api.ProductPriceDto;
	product_name?: api.LocaleStringDto;
	images?: api.ProductImagesDto;
	integration?: string[];
	categories?: api.CategoryDto[];
}
export interface SummaryDataDto {
	items: SummaryItemDto[];
	addresses: {
		shipping?: api.AddressDataDto;
		billing?: api.AddressDataDto;
	};
	metadata: {
		shipping_method?: string;
		order_message?: string;
		user_locale?: string;
		user_info?: api.OrderUserInfoDto;
		reference_code?: string;
		purchase_order_number?: string;
	};
}

//#endregion

//#region State

export interface State {
	isPersistedStateLoaded: boolean;
	currentCheckoutStep: CheckoutStepNumber;
	resetReason?: ResetCheckoutReason;
	isOnMobileOrderContents: boolean;
	// TODO: Use CartItem type instead of CartItemWithProductDto for safer types.
	items: api.CartItemWithProductDto[];
	itemErrors: Map<string, CartItemError>;
	addedItem?: api.CartItemDto;
	shippingAddress?: AddressFormModel;
	billingAddress?: AddressFormModel;
	isShippingAddressValid: boolean;
	isBillingAddressValid: boolean;
	paymentMethod?: PaymentMethod;
	onlinePaymentMethodGroup?: OnlinePaymentMethodGroup;
	onlinePaymentMethod?: string;
	shippingMethod?: string;
	discountCode?: string;
	referenceCode?: string;
	purchaseOrderNumber?: string;
	orderMessage?: string;
	userDefinedDeliveryDate?: string;
	isUserDefinedDeliveryDateValid?: boolean;
	isCheckingUserDefinedDeliveryDate?: boolean;
	isOrderSummaryValid: boolean;
	newOrder?: NewOrder;
	newOrderAccessToken?: string;
	fetchedOrder?: FetchedOrder;
	billingPrices?: BillingPrices;
	shippingPrices?: ShippingPrice[];
	shippingPricesError?: ShippingPricesError;
	additionalPrices?: AdditionalPrices;
	orderPrice: RemoteData<ApiError, api.OrderPriceDto>;
	itemPrices: Map<string, RemoteData<ApiError, api.ProductPriceDto>>;
	userInfo: UserInfo;
	quoteFormSubmit?: QuoteFormSubmit;
	orderFormSubmit?: OrderFormSubmit;
	preflightQueue: Map<string, StartPreflightOptions>;
	availableShippingMethods?: api.ShippingMethod[];
	deliveryDates?: DeliveryDates;
	showPricesWithTax: boolean;
	submitAddressForm?: OrderAddressType;
	isCxmlPunchout: boolean; // Forwarded from auth reducer.
	cxmlSessionId?: number;
	cxmlUserInfo?: api.UserDto;
}

export const initialState: State = {
	isPersistedStateLoaded: false,
	isCxmlPunchout: false,
	currentCheckoutStep: 1,
	resetReason: undefined,
	isOnMobileOrderContents: true,
	discountCode: undefined,
	referenceCode: undefined,
	purchaseOrderNumber: undefined,
	items: [],
	itemErrors: new Map(),
	newOrder: undefined,
	newOrderAccessToken: undefined,
	fetchedOrder: undefined,
	itemPrices: new Map(),
	orderPrice: new Initialized(),
	userInfo: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
	},
	quoteFormSubmit: undefined,
	orderFormSubmit: undefined,
	preflightQueue: new Map(),
	showPricesWithTax: true,
	isShippingAddressValid: false,
	isBillingAddressValid: false,
	isOrderSummaryValid: false,
};

//#endregion

//#region Actions

export const SET_PERSISTED_STATE_LOADED = 'mygrano/checkout/SET_PERSISTED_STATE_LOADED';
export const setPersistedStateLoaded = (isLoaded: boolean) =>
	({ type: SET_PERSISTED_STATE_LOADED, payload: isLoaded }) as const;

const propsToKeepOnReset = ['isPersistedStateLoaded'] as const;
export const RESET_CHECKOUT_FLOW = 'mygrano/checkout/RESET_CHECKOUT_FLOW';
export const resetCheckoutFlow = (reason?: ResetCheckoutReason) =>
	({ type: RESET_CHECKOUT_FLOW, payload: reason }) as const;

export const RESET_CHECKOUT_FLOW_KEEP_CART = 'mygrano/checkout/RESET_CHECKOUT_FLOW_KEEP_CART';
export const resetCheckoutFlowKeepCart = (reason: ResetCheckoutReason) =>
	({ type: RESET_CHECKOUT_FLOW_KEEP_CART, payload: reason }) as const;

export const UPDATE_PAYMENT_METHOD = 'mygrano/checkout/UPDATE_PAYMENT_METHOD';
export const updatePaymentMethod = (method: PaymentMethod) =>
	({ type: UPDATE_PAYMENT_METHOD, payload: method }) as const;

export const UPDATE_ONLINE_PAYMENT_METHOD = 'mygrano/checkout/UPDATE_ONLINE_PAYMENT_METHOD';
export const updateOnlinePaymentMethod = (group: OnlinePaymentMethodGroup, id: string) =>
	({ type: UPDATE_ONLINE_PAYMENT_METHOD, payload: { group, id } }) as const;

export const UPDATE_SHIPPING_METHOD = 'mygrano/checkout/UPDATE_SHIPPING_METHOD';
export const updateShippingMethod = (method: string) =>
	({ type: UPDATE_SHIPPING_METHOD, payload: method }) as const;

export const UPDATE_REFERENCE_CODE = 'mygrano/checkout/UPDATE_REFERENCE_CODE';
export const updateReferenceCode = (code: string) =>
	({ type: UPDATE_REFERENCE_CODE, payload: code }) as const;

export const UPDATE_PURCHASE_ORDER_NUMBER = 'mygrano/checkout/UPDATE_PURCHASE_ORDER_NUMBER';
export const updatePurchaseOrderNumber = (poNumber: string) =>
	({ type: UPDATE_PURCHASE_ORDER_NUMBER, payload: poNumber }) as const;

export const UPDATE_USER_DEFINED_DELIVERY_DATE = 'mygrano/checkout/UPDATE_USER_DEFINED_DELIVERY_DATE';
export const updateUserDefinedDeliveryDate = (date?: string, isValid = false) =>
	({ type: UPDATE_USER_DEFINED_DELIVERY_DATE, payload: { date, isValid } }) as const;

export const CACHED_DELIVERY_DATE = 'mygrano/checkout/CACHED_DELIVERY_DATE';
export const CHECK_USER_DEFINED_DELIVERY_DATE = 'mygrano/checkout/CHECK_USER_DEFINED_DELIVERY_DATE';
export const checkUserDefinedDeliveryDate = (date: string, minDeliveryTime: number) => {
	// If date is invalid user current date.
	const cacheKey =
		`${isValid(date) ? format(date, 'yyyy-MM-dd') : 'invalid'} - ${minDeliveryTime}` as const;
	if (deliveryDateCache.has(cacheKey)) {
		return {
			type: CACHED_DELIVERY_DATE,
			error: false,
			payload: { result: deliveryDateCache.get(cacheKey), isCached: true },
		} as const;
	}
	return ApiShippingMethod.isValidDeliveryDate(date, minDeliveryTime);
};

export const UPDATE_DISCOUNT_CODE = 'mygrano/checkout/UPDATE_DISCOUNT_CODE';
export const updateDiscountCode = (code?: string) =>
	({ type: UPDATE_DISCOUNT_CODE, payload: code }) as const;

export const UPDATE_ORDER_MESSAGE = 'mygrano/checkout/UPDATE_ORDER_MESSAGE';
export const updateOrderMessage = (msg: string) =>
	({ type: UPDATE_ORDER_MESSAGE, payload: msg }) as const;

export const NEXT_STEP = 'mygrano/checkout/NEXT_STEP';
export const nextStep = () => ({ type: NEXT_STEP }) as const;

export const PREVIOUS_STEP = 'mygrano/checkout/PREVIOUS_STEP';
export const previousStep = () => ({ type: PREVIOUS_STEP }) as const;

export const UPDATE_ADDRESS = 'mygrano/checkout/UPDATE_ADDRESS';
export const updateAddress = (type: OrderAddressType, address: AddressFormModel) =>
	({ type: UPDATE_ADDRESS, payload: { type, address } }) as const;

export const SET_STEP = 'mygrano/checkout/SET_STEP';
export const setStep = (step: number) => ({ type: SET_STEP, payload: step }) as const;

export const SET_MOBILE_ORDER_CONTENT_STATUS = 'mygrano/checkout/SET_MOBILE_ORDER_CONTENT_STATUS';
export const setMobileOrderContentStatus = (status: boolean) =>
	({ type: SET_MOBILE_ORDER_CONTENT_STATUS, payload: status }) as const;

export const ADD_PRODUCT = 'mygrano/checkout/ADD_PRODUCT';
export const addProduct = (
	id: string,
	product: api.CartItemProductDto,
	options: api.CartItemOptionsDto,
	productFlowData?: api.CartItemProductFlowDataDto,
	isProcessing?: boolean,
) =>
	({
		type: ADD_PRODUCT,
		payload: {
			id,
			product,
			options,
			productFlowData,
			isProcessing,
		},
	}) as const;

export const SET_ITEMS = 'mygrano/checkout/SET_PRODUCTS';
export const setCartItems = (items: api.CartItemWithProductDto[]) =>
	({ type: SET_ITEMS, payload: Array.isArray(items) ? [...items] : undefined }) as const;

export const SET_CXML_SESSION = 'mygrano/checkout/SET_CXML_SESSION';
export const setCxmlSession = (id: number) => ({ type: SET_CXML_SESSION, payload: id }) as const;

export const SET_CXML_USER_INFO = 'mygrano/checkout/SET_CXML_USER_INFO';
export const setCxmlUserInfo = (user: api.UserDto) =>
	({ type: SET_CXML_USER_INFO, payload: user }) as const;

export const MODIFY_PRODUCT = 'mygrano/checkout/MODIFY_PRODUCT';
export const modifyProduct = (
	id: string,
	product: CartItem['product'],
	options: api.CartItemOptionsDto,
	price?: api.ProductPriceDto,
	productFlowData: api.CartItemProductFlowDataDto | undefined = undefined,
	hasNewPrintfile = false,
) =>
	({
		type: MODIFY_PRODUCT,
		payload: { id, product, options, price, productFlowData, hasNewPrintfile },
	}) as const;

export const modifyProductOptions = (id: string, options: api.CartItemOptionsDto) =>
	({ type: MODIFY_PRODUCT, payload: { id, options } }) as const;

export const REMOVE_PRINTFILE = 'mygrano/checkout/REMOVE_PRINTFILE';
export const removePrintfile = ({ item_id: itemId, key }: { item_id: string; key: string }) =>
	({ type: REMOVE_PRINTFILE, payload: { item_id: itemId, key } }) as const;

export const REMOVE_PRODUCT = 'mygrano/checkout/REMOVE_PRODUCT';
/** @deprecated Use removeItem instead. */
export const removeProduct = (index: number) => ({ type: REMOVE_PRODUCT, payload: index }) as const;

export const REMOVE_ITEM = 'mygrano/checkout/REMOVE_ITEM';
export const removeItem = (id: string) => ({ type: REMOVE_ITEM, payload: id }) as const;

export const RESET_ADDED_PRODUCT = 'mygrano/checkout/RESET_ADDED_PRODUCT';
export const resetAddedProduct = () => ({ type: RESET_ADDED_PRODUCT }) as const;

export const SET_STATE = 'mygrano/checkout/SET_STATE';
export const setState = (state: Partial<State>) => ({ type: SET_STATE, payload: state }) as const;

export const QUEUE_PREFLIGHT = 'mygrano/checkout/QUEUE_PREFLIGHT';
export const queuePreflight = (key: string, options: StartPreflightOptions) =>
	({ type: QUEUE_PREFLIGHT, payload: { key, options } }) as const;

export const REMOVE_FROM_PREFLIGHT = 'mygrano/checkout/REMOVE_FROM_PREFLIGHT';
export const removeFromPreflight = (key: string) =>
	({ type: REMOVE_FROM_PREFLIGHT, payload: key }) as const;

export const UPDATE_PRINTFILE = 'mygrano/checkout/UPDATE_PRINTFILE';
export const updatePrintfile = (printfile: Partial<api.PrintfileDto>) =>
	({ type: UPDATE_PRINTFILE, payload: printfile }) as const;

export const UPDATE_USER_INFO = 'mygrano/checkout/UPDATE_USER_INFO';
export const updateUserInfo = (userInfo: UserInfo) =>
	({ type: UPDATE_USER_INFO, payload: { userInfo } }) as const;

export const RESET_QUOTE_FORM_STATUS = 'mygrano/checkout/RESET_QUOTE_FORM_STATUS';
export const resetQuoteFormStatus = () => ({ type: RESET_QUOTE_FORM_STATUS }) as const;

export const RESET_ORDER_FORM_STATUS = 'mygrano/checkout/RESET_ORDER_FORM_STATUS';
export const resetOrderFormStatus = () => ({ type: RESET_ORDER_FORM_STATUS }) as const;

export const SET_SHOW_PRICES_WITH_TAX = 'mygrano/checkout/SET_SHOW_PRICES_WITH_TAX';
export const setShowPricesWithTax = (withTax: boolean) =>
	({ type: SET_SHOW_PRICES_WITH_TAX, payload: withTax }) as const;

export const SET_IS_ADDRESS_VALID = 'mygrano/checkout/SET_IS_ADDRESS_VALID';
export const setIsAddressValid = (type: string, isValid: boolean) =>
	({ type: SET_IS_ADDRESS_VALID, payload: { type, isValid } }) as const;

export const SET_IS_ORDER_SUMMARY_VALID = 'mygrano/checkout/SET_IS_ORDER_SUMMARY_VALID';
export const setIsOrderSummaryValid = (isValid: boolean) =>
	({ type: SET_IS_ORDER_SUMMARY_VALID, payload: isValid }) as const;

export const SUBMIT_ADDRESS_FORM = 'mygrano/checkout/SUBMIT_ADDRESS_FORM';
export const submitAddressForm = (type: OrderAddressType) =>
	({ type: SUBMIT_ADDRESS_FORM, payload: type }) as const;

export const RESET_ADDRESS_FORM_SUBMIT = 'mygrano/checkout/RESET_ADDRESS_FORM_SUBMIT';
export const resetAddressFormSubmit = () => ({ type: RESET_ADDRESS_FORM_SUBMIT }) as const;

export const REPORT_ITEM_ERROR = 'mygrano/checkout/REPORT_ITEM_ERROR';
export const reportItemError = (error: CartItemError) =>
	({ type: REPORT_ITEM_ERROR, payload: error }) as const;

export const DISMISS_ITEM_ERROR = 'mygrano/checkout/DISMISS_ITEM_ERROR';
export const dismissItemError = (error: CartItemError) =>
	({ type: DISMISS_ITEM_ERROR, payload: error }) as const;

export const GET_PRICE_FOR_ITEM_START = 'mygrano/checkout/GET_PRICE_FOR_ITEM_START';
export const GET_PRICE_FOR_ITEM = 'mygrano/checkout/GET_PRICE_FOR_ITEM';
/**
 * Fetches the price for a given cart item.
 * @param item - The cart item for which the price is to be fetched.
 */
export function getPriceForItem(item: CartItem, options?: GetPriceForProductOptions): api.AsyncAction {
	return (dispatch) => {
		const { id } = item.product;
		dispatch({ type: GET_PRICE_FOR_ITEM_START, meta: { info: { item }, params: { id, options } } });
		return getPriceForProduct(id, options).then((response) =>
			dispatch({
				type: GET_PRICE_FOR_ITEM,
				payload: response.data,
				error: response.error,
				meta: {
					res: response.raw,
					info: { item },
				},
			}),
		);
	};
}

type CheckoutAction =
	| Exclude<
			ReturnType<
				| typeof setPersistedStateLoaded
				| typeof resetCheckoutFlow
				| typeof resetCheckoutFlowKeepCart
				| typeof updatePaymentMethod
				| typeof updateOnlinePaymentMethod
				| typeof updateShippingMethod
				| typeof updateReferenceCode
				| typeof updateUserDefinedDeliveryDate
				| typeof checkUserDefinedDeliveryDate
				| typeof updateDiscountCode
				| typeof updateOrderMessage
				| typeof nextStep
				| typeof previousStep
				| typeof updateAddress
				| typeof setStep
				| typeof setMobileOrderContentStatus
				| typeof addProduct
				| typeof setCartItems
				| typeof setCxmlSession
				| typeof setCxmlUserInfo
				| typeof modifyProduct
				| typeof modifyProductOptions
				| typeof removePrintfile
				| typeof removeProduct
				| typeof removeItem
				| typeof resetAddedProduct
				| typeof setState
				| typeof queuePreflight
				| typeof removeFromPreflight
				| typeof updatePrintfile
				| typeof updateUserInfo
				| typeof resetQuoteFormStatus
				| typeof resetOrderFormStatus
				| typeof setShowPricesWithTax
				| typeof setIsAddressValid
				| typeof setIsOrderSummaryValid
				| typeof submitAddressForm
				| typeof resetAddressFormSubmit
				| typeof updatePurchaseOrderNumber
				| typeof reportItemError
				| typeof dismissItemError
			>,
			api.AsyncAction
	  >
	| ApiAction<typeof ApiUpload.START_PREFLIGHT_START, never, { key: string }>
	| ApiAction<
			typeof ApiUpload.START_PREFLIGHT,
			{ item_id: string; printfile: api.PrintfileDto },
			{ key: string }
	  >
	| ApiAction<typeof ApiOrder.CREATE_START, never>
	| ApiAction<typeof ApiOrder.CREATE, api.OrderDto>
	| ApiAction<typeof ApiOrder.GET_ORDER, api.OrderDto>
	| ApiAction<typeof ApiOrder.CREATE_PRODUCT_QUOTE_START, never>
	| ApiAction<typeof ApiOrder.CREATE_PRODUCT_QUOTE, api.SuccessDto>
	| ApiAction<typeof ApiOrder.CREATE_FORM_ORDER_START, never>
	| ApiAction<typeof ApiOrder.CREATE_FORM_ORDER, api.SuccessDto>
	| ApiAction<typeof ApiShippingMethod.ALLOWED_SHIPPING_METHODS, api.ShippingMethod[]>
	| ApiAction<typeof ApiShippingMethod.GET_DELIVERY_DATES, DeliveryDates>
	| ApiAction<typeof ApiShippingMethod.IS_VALID_DELIVERY_DATE_START, never>
	| ApiAction<
			typeof ApiShippingMethod.IS_VALID_DELIVERY_DATE,
			ApiShippingMethod.IS_VALID_DELIVERY_DATE & { isCached?: false }
	  >
	| ApiAction<
			typeof ApiPrice.GET_PRICES,
			{ billing?: BillingPrices[string][]; additional?: api.AdditionalPrice[] }
	  >
	| ApiAction<typeof ApiPrice.GET_SHIPPING_PRICES, ShippingPrice[]>
	| ApiAction<typeof ApiPrice.GET_PRICE_FOR_ORDER_START, never>
	| ApiAction<typeof ApiPrice.GET_PRICE_FOR_ORDER, api.OrderPriceDto>
	| ApiAction<typeof GET_PRICE_FOR_ITEM_START, never, { item: CartItem }>
	| ApiAction<typeof GET_PRICE_FOR_ITEM, api.ProductPriceDto, { item: CartItem }>;

//#endregion

/** The checkout reducer function. */
export function reducer(state: State = initialState, action: CheckoutAction): State {
	switch (action.type) {
		case RESET_CHECKOUT_FLOW:
			return {
				...state,
				...omit(initialState, propsToKeepOnReset),
				resetReason: action.payload,
			};

		case RESET_CHECKOUT_FLOW_KEEP_CART:
			return {
				...initialState,
				items: [...state.items],
				resetReason: action.payload,
			};

		case SET_CXML_SESSION:
			return { ...state, cxmlSessionId: action.payload };

		case SET_CXML_USER_INFO:
			return { ...state, cxmlUserInfo: action.payload };

		case UPDATE_PAYMENT_METHOD:
			return { ...state, paymentMethod: action.payload };

		case UPDATE_ONLINE_PAYMENT_METHOD: {
			const { group, id } = action.payload as { group: OnlinePaymentMethodGroup; id: string };
			return {
				...state,
				onlinePaymentMethodGroup: group,
				onlinePaymentMethod: id,
			};
		}

		case UPDATE_SHIPPING_METHOD:
			return { ...state, shippingMethod: action.payload };

		case UPDATE_REFERENCE_CODE:
			return { ...state, referenceCode: action.payload };

		case UPDATE_PURCHASE_ORDER_NUMBER:
			return { ...state, purchaseOrderNumber: action.payload };

		case UPDATE_DISCOUNT_CODE:
			return { ...state, discountCode: action.payload };

		case UPDATE_ORDER_MESSAGE:
			return { ...state, orderMessage: action.payload };

		case UPDATE_USER_DEFINED_DELIVERY_DATE:
			return {
				...state,
				userDefinedDeliveryDate: action.payload.date,
				isUserDefinedDeliveryDateValid: action.payload.isValid,
			};

		case NEXT_STEP:
			return { ...state, currentCheckoutStep: Math.min(state.currentCheckoutStep + 1, 4) };

		case PREVIOUS_STEP:
			return { ...state, currentCheckoutStep: Math.max(state.currentCheckoutStep - 1, 1) };

		case UPDATE_ADDRESS:
			return {
				...state,
				[`${action.payload.type}Address`]: { ...action.payload.address },
			};

		case SET_STEP:
			return {
				...state,
				currentCheckoutStep: Math.min(Math.max(action.payload, 1), 4) || 1,
			};

		case SET_MOBILE_ORDER_CONTENT_STATUS:
			return { ...state, isOnMobileOrderContents: action.payload };

		case ADD_PRODUCT: {
			const payload = action.payload as api.CartItemWithProductDto;
			const hasPrintfile = !!payload.options.printfile?.key;
			const hasProfile = !!payload.product?.metadata?.profile;
			const matchingItemIndex = state.items.findIndex((item) => item.id === action.payload.id);

			/**
			 * If this item is already in the shopping cart, replace it. This way any changes made to options,
			 * printfile and proof images will be up to date. (MTS-516)
			 */
			if (matchingItemIndex > -1 && hasPrintfile) {
				const items = state.items.map((item, index) => (index === matchingItemIndex ? payload : item));
				return {
					...state,
					items,
				};
			}

			if (matchingItemIndex > -1) return state;

			if (hasPrintfile && hasProfile) {
				const itemWithSamePrintfile = state.items?.find(
					(item) => item.options.printfile?.key === payload.options.printfile?.key,
				);
				if (itemWithSamePrintfile) {
					const newItem: api.CartItemWithProductDto = {
						...payload,
						options: {
							...payload.options,
							printfile: {
								...payload.options.printfile!,
								status: itemWithSamePrintfile.options.printfile!.status,
							},
						},
						isProcessing: itemWithSamePrintfile.isProcessing,
					};
					return {
						...state,
						items: [...state.items, newItem],
						addedItem: payload as api.CartItemDto,
					};
				}
			}

			return {
				...state,
				items: [...state.items, { ...payload }],
				addedItem: payload as api.CartItemDto,
			};
		}

		case SET_ITEMS:
			return { ...state, items: action.payload ?? [], addedItem: undefined };

		case MODIFY_PRODUCT:
			return {
				...state,
				items: state.items.map((item) => {
					if (item.id === action.payload.id) {
						return {
							...item,
							...(action.payload as api.CartItemWithProductDto),
						};
					}
					return item;
				}),
			};

		case REMOVE_PRINTFILE:
			return {
				...state,
				items: state.items.map((item) => {
					if (item.id === action.payload.item_id)
						return {
							...item,
							options: {
								...item.options,
								printfile: undefined,
							},
							isProcessing: false,
						};
					return item;
				}),
				preflightQueue: removePrintfileFromQueue(state, action.payload.key),
			};

		case REMOVE_PRODUCT:
			return {
				...state,
				items: state.items.filter((_value, index) => index !== action.payload),
			};

		case REMOVE_ITEM:
			return {
				...state,
				items: state.items.filter((item) => item.id !== action.payload),
			};

		case RESET_ADDED_PRODUCT:
			return { ...state, addedItem: undefined };

		case QUEUE_PREFLIGHT:
			return {
				...state,
				preflightQueue: new Map(state.preflightQueue).set(action.payload.key, action.payload.options),
			};

		case REMOVE_FROM_PREFLIGHT:
			return {
				...state,
				preflightQueue: removePrintfileFromQueue(state, action.payload),
			};

		case UPDATE_PRINTFILE:
			return {
				...state,
				items: state.items.map((item) => {
					if (action.payload.key !== item.options?.printfile?.key) return item;
					return {
						...item,
						options: {
							...item.options,
							printfile: {
								...item.options.printfile!,
								...action.payload,
							},
						},
						isProcessing: action.payload.status !== 'processed',
					};
				}),
			};

		case SET_STATE:
			return { ...state, ...action.payload };

		case SET_PERSISTED_STATE_LOADED:
			return { ...state, isPersistedStateLoaded: action.payload };

		case UPDATE_USER_INFO:
			return { ...state, userInfo: action.payload.userInfo };

		case RESET_QUOTE_FORM_STATUS:
			return { ...state, quoteFormSubmit: initialState.quoteFormSubmit };

		case RESET_ORDER_FORM_STATUS:
			return { ...state, orderFormSubmit: initialState.orderFormSubmit };

		case SET_SHOW_PRICES_WITH_TAX:
			return { ...state, showPricesWithTax: action.payload };

		case SET_IS_ADDRESS_VALID:
			return {
				...state,
				isShippingAddressValid:
					action.payload.type === 'shipping' ? action.payload.isValid : state.isShippingAddressValid,
				isBillingAddressValid:
					action.payload.type === 'billing' ? action.payload.isValid : state.isBillingAddressValid,
			};

		case SET_IS_ORDER_SUMMARY_VALID:
			return { ...state, isOrderSummaryValid: action.payload };

		case SUBMIT_ADDRESS_FORM:
			return { ...state, submitAddressForm: action.payload };

		case RESET_ADDRESS_FORM_SUBMIT:
			return { ...state, submitAddressForm: initialState.submitAddressForm };

		case REPORT_ITEM_ERROR: {
			const error = action.payload;
			return {
				...state,
				itemErrors: new Map([...state.itemErrors, [error.key, error]]),
			};
		}

		case DISMISS_ITEM_ERROR: {
			const error = action.payload;
			const itemErrors = new Map(state.itemErrors);
			itemErrors.delete(error.key);
			return { ...state, itemErrors };
		}

		case ApiUpload.START_PREFLIGHT_START:
			return {
				...state,
				items: state.items.map((item) => {
					return action.meta.info.key === item?.options?.printfile?.key
						? {
								...item,
								// This is duplicate logic. The start-preflight API endpoint will not actually queue a preflight if there's
								// no profile set and will return immediately telling that the file is not actually being processed. For
								// legacy reasons, the start-preflight route is called with editor files also.
								isProcessing: !!item?.product?.metadata?.profile,
							}
						: item;
				}),
				preflightQueue: removePrintfileFromQueue(state, action.meta.info.key),
			};

		case ApiUpload.START_PREFLIGHT:
			return {
				...state,
				items: state.items.map((item) => {
					if (action.meta.info.key !== item?.options?.printfile?.key) return item;
					return {
						...item,
						options: {
							...item.options,
							printfile:
								action.error === true
									? { ...item.options.printfile, report: PREFLIGHT_START_FAILED_REPORT }
									: action.payload.printfile,
						},
						isProcessing: action.error !== true && action.payload.printfile.status !== 'processed',
					};
				}),
			};

		case ApiOrder.CREATE_START:
			return {
				...state,
				newOrder: { ...state.newOrder, isProcessing: true, isError: false, isSuccess: false },
				fetchedOrder: undefined,
			};

		case ApiOrder.CREATE:
			return {
				...state,
				newOrder: {
					...state.newOrder,
					isProcessing: false,
					isSuccess: !action.error,
					isError: action.error,
					content: action.error === true ? undefined : action.payload,
					errorTrace: parseApiActionError(action)?.trace,
				},
				newOrderAccessToken: action.error === true ? undefined : action.payload.access_token,
			};

		case ApiOrder.GET_ORDER:
			return {
				...state,
				fetchedOrder: {
					...state.fetchedOrder,
					isSuccess: !action.error,
					isError: action.error,
					content: action.error === true ? undefined : action.payload,
				},
			};

		case ApiOrder.CREATE_PRODUCT_QUOTE_START:
			return {
				...state,
				quoteFormSubmit: { isProcessing: true, isSuccess: false, isError: false },
			};

		case ApiOrder.CREATE_PRODUCT_QUOTE:
			return {
				...state,
				quoteFormSubmit: {
					isProcessing: false,
					isSuccess: !action.error,
					isError: action.error,
				},
			};

		case ApiOrder.CREATE_FORM_ORDER_START:
			return { ...state, orderFormSubmit: { isProcessing: true, isSuccess: false, isError: false } };

		case ApiOrder.CREATE_FORM_ORDER:
			return {
				...state,
				orderFormSubmit: {
					isProcessing: false,
					isSuccess: !action.error,
					isError: action.error,
				},
			};

		case ApiShippingMethod.ALLOWED_SHIPPING_METHODS: {
			return { ...state, availableShippingMethods: action.error === true ? [] : action.payload };
		}

		case ApiShippingMethod.GET_DELIVERY_DATES: {
			return { ...state, deliveryDates: action.error === true ? {} : action.payload };
		}

		case ApiShippingMethod.IS_VALID_DELIVERY_DATE_START: {
			return {
				...state,
				isCheckingUserDefinedDeliveryDate: true,
				isUserDefinedDeliveryDateValid: undefined,
			};
		}

		case CACHED_DELIVERY_DATE:
		case ApiShippingMethod.IS_VALID_DELIVERY_DATE: {
			if (action.error !== true && action.payload?.isCached !== true) {
				const { date, minDeliveryTime, result } = action.payload;
				const cacheKey =
					`${isValid(date) ? format(date!, 'yyyy-MM-dd') : 'invalid'} - ${minDeliveryTime!}` as const;
				deliveryDateCache.set(cacheKey, result === true);
			}
			return {
				...state,
				isCheckingUserDefinedDeliveryDate: false,
				isUserDefinedDeliveryDateValid:
					action.error === true ? undefined : action.payload?.result === true,
			};
		}

		case ApiPrice.GET_PRICES: {
			const prices = action.error === true ? undefined : action.payload;
			const billingPrices: BillingPrices = {};
			for (const method of prices?.billing ?? []) {
				billingPrices[method.key!] = method;
			}
			const additionalPrices: AdditionalPrices = {};
			for (const price of prices?.additional ?? []) {
				additionalPrices[price.type] = {
					...additionalPrices[price.type],
					[price.name]: price.price,
				};
			}
			return { ...state, billingPrices, additionalPrices };
		}

		case ApiPrice.GET_SHIPPING_PRICES: {
			return {
				...state,
				shippingPrices: isApiActionSuccess(action) ? action.payload : [],
				shippingPricesError: parseApiActionError(action),
			};
		}

		case GET_PRICE_FOR_ITEM_START:
			return {
				...state,
				itemPrices: new Map([...state.itemPrices, [action.meta.info.item.id, new Pending()]]),
			};

		case GET_PRICE_FOR_ITEM:
			return {
				...state,
				itemPrices: new Map([
					...state.itemPrices,
					[
						action.meta.info.item.id,
						isApiActionSuccess(action) ? new Success(action.payload) : new Failure(action.payload),
					],
				]),
			};

		case ApiPrice.GET_PRICE_FOR_ORDER_START:
			return {
				...state,
				orderPrice: new Pending(),
			};

		case ApiPrice.GET_PRICE_FOR_ORDER:
			return {
				...state,
				orderPrice: isApiActionSuccess(action)
					? new Success(action.payload)
					: new Failure(action.payload),
			};

		default:
			return state;
	}
}

//#region Selectors

export const getState = setName(
	'getState',
	createSelector(
		(state: RootReducer.State) => state.checkout,
		// Forward this state from auth reducer to checkout reducer.
		(state: RootReducer.State) => !!state?.auth?.cxmlToken || !!state.checkout?.cxmlSessionId,
		// Combine the state from both reducers.
		(checkoutState, isCxmlPunchout) => ({ ...checkoutState, isCxmlPunchout }),
	),
);

export const getIsPersistedStateLoaded = setName(
	'getIsPersistedStateLoaded',
	createSelector(getState, (state: State) => state.isPersistedStateLoaded),
);

export const getCartItems = setName(
	'getCartItems',
	createSelector(getState, (state: State) => state.items),
);

export const getIsCxmlSession = setName(
	'getIsCxmlSession',
	createSelector(getState, (state: State) => state.isCxmlPunchout),
);

export const getCxmlSessionId = setName(
	'getCxmlSessionId',
	createSelector(getState, (state: State) => state.cxmlSessionId),
);

export const getCxmlUserInfo = setName(
	'getCxmlUserInfo',
	createSelector(getState, (state: State) => state.cxmlUserInfo),
);

export const getCartItem = (itemId: string) =>
	setName(
		'getCartItem',
		createSelector(getCartItems, (items: api.CartItemWithProductDto[]) =>
			items?.find((item) => item.id === itemId),
		),
	);

export const getProductCount = setName(
	'getProductCount',
	createSelector(getCartItems, (items) => items?.length),
);

export const getAddedProduct = setName(
	'getAddedProduct',
	createSelector(getState, (state: State) => state.addedItem),
);

export const getAddress = <T extends OrderAddressType>(type: T) =>
	setName(
		'getAddress',
		createSelector(getState, (state: State) => state[`${type}Address`]),
	);

export const getCurrentCheckoutStep = setName(
	'getCurrentCheckoutStep',
	createSelector(getState, (state: State) => state.currentCheckoutStep),
);

export const getPaymentMethod = setName(
	'getPaymentMethod',
	createSelector(getState, (state: State) => state.paymentMethod),
);

export const getOnlinePaymentMethod = setName(
	'getOnlinePaymentMethod',
	createSelector(getState, (state: State) => ({
		group: state.onlinePaymentMethodGroup,
		id: state.onlinePaymentMethod,
	})),
);

export const getShippingMethod = setName(
	'getShippingMethod',
	createSelector(getState, (state: State) => state.shippingMethod),
);

export const getReferenceCode = setName(
	'getReferenceCode',
	createSelector(getState, (state: State) => state.referenceCode),
);

export const getPurchaseOrderNumber = setName(
	'getPurchaseOrderNumber',
	createSelector(getState, (state: State) => state.purchaseOrderNumber),
);

export const getDiscountCode = setName(
	'getDiscountCode',
	createSelector(getState, (state: State) => state.discountCode),
);

export const getOrderMessage = setName(
	'getOrderMessage',
	createSelector(getState, (state: State) => state.orderMessage),
);

export const getPreflightQueue = setName(
	'getPreflightQueue',
	createSelector(getState, (state: State) => state.preflightQueue),
);

export const getUnprocessedPrintfiles = setName(
	'getUnprocessedPrintfiles',
	createSelector(getState, (state) =>
		state.items
			.map((item) => item.options.printfile)
			.filter((printfile): printfile is api.PrintfileDto => printfile?.status === 'unprocessed'),
	),
);

export const getMobileOrderContentStatus = setName(
	'getMobileOrderContentStatus',
	createSelector(getState, (state) => state.isOnMobileOrderContents),
);

export const getNewOrder = setName(
	'getNewOrder',
	createSelector(getState, (state) => state.newOrder),
);

export const getNewOrderProcessing = setName(
	'getNewOrderProcessing',
	createSelector(getNewOrder, (newOrder) => newOrder?.isProcessing),
);

export const getNewOrderSuccess = setName(
	'getNewOrderSuccess',
	createSelector(getNewOrder, (newOrder) => newOrder?.isSuccess),
);

export const getNewOrderError = setName(
	'getNewOrderError',
	createSelector(getNewOrder, (newOrder) => newOrder?.isError),
);

export const getNewOrderAccessToken = setName(
	'getNewOrderAccessToken',
	createSelector(getState, (state) => state.newOrderAccessToken),
);

export const getFetchedOrder = setName(
	'getFetchedOrder',
	createSelector(getState, (state) => state.fetchedOrder),
);

export const getBillingPrices = setName(
	'getBillingPrices',
	createSelector(getState, (state) => state.billingPrices),
);

export const getShippingPrices = setName(
	'getShippingPrices',
	createSelector(getState, (state) => state.shippingPrices),
);

export const getShippingPricesError = setName(
	'getShippingPricesError',
	createSelector(getState, (state) => state.shippingPricesError),
);

export const getPremediaPrices = setName(
	'getPremediaPrices',
	createSelector(getState, (state) => state?.additionalPrices?.premedia),
);

export const getAdditionalPrices = (type?: api.AdditionalPrice['type']) =>
	setName(
		'getAdditionalPrices',
		createSelector(getState, (state) =>
			type ? state.additionalPrices?.[type] : state.additionalPrices,
		),
	);

export const getOrderPrice = setName(
	'getOrderPrice',
	createSelector(getState, (state) => state.orderPrice),
);

export const getItemPrices = setName(
	'getItemPrices',
	createSelector(getState, (state) => state.itemPrices),
);

export const getDiscountVoucherStatus = setName(
	'getDiscountVoucherStatus',
	createSelector(getOrderPrice, (orderPrice) => foldSuccess(orderPrice)?.discountVoucherStatus),
);

export const getUserInfo = setName(
	'getUserInfo',
	createSelector(getState, (state) => state.userInfo),
);

export const isCartItem = (item: unknown): item is CartItem =>
	Number.isFinite((item as api.CartItemWithProductDto)?.product?.id) &&
	// An ordered product has an uuid field, a cart item does not.
	!('uuid' in (item as api.CartItemWithProductDto));

export const getQuoteFormSubmitProcessing = setName(
	'getQuoteFormSubmitProcessing',
	createSelector(getState, (state) => state.quoteFormSubmit?.isProcessing ?? false),
);

export const getQuoteFormSubmitSuccess = setName(
	'getQuoteFormSubmitSuccess',
	createSelector(getState, (state) => state.quoteFormSubmit?.isSuccess ?? false),
);

export const getQuoteFormSubmitError = setName(
	'getQuoteFormSubmitError',
	createSelector(getState, (state) => state.quoteFormSubmit?.isError ?? false),
);

export const getOrderFormSubmitProcessing = setName(
	'getOrderFormSubmitProcessing',
	createSelector(getState, (state) => state.orderFormSubmit?.isProcessing ?? false),
);

export const getOrderFormSubmitSuccess = setName(
	'getOrderFormSubmitSuccess',
	createSelector(getState, (state) => state.orderFormSubmit?.isSuccess ?? false),
);

export const getOrderFormSubmitError = setName(
	'getOrderFormSubmitError',
	createSelector(getState, (state) => state.orderFormSubmit?.isError ?? false),
);

export const getAvailableShippingMethods = setName(
	'getAvailableShippingMethods',
	createSelector(getState, (state: State) => state.availableShippingMethods),
);

export const getDeliveryDates = setName(
	'getDeliveryDates',
	createSelector(getState, (state: State) => state.deliveryDates),
);

export const getUserDefinedDeliveryDate = setName(
	'getUserDefinedDeliveryDate',
	createSelector(getState, (state: State) => state.userDefinedDeliveryDate),
);

export const getIsCheckingUserDefinedDeliveryDate = setName(
	'getIsCheckingUserDefinedDeliveryDate',
	createSelector(getState, (state: State) => state.isCheckingUserDefinedDeliveryDate),
);

export const getIsUserDefinedDeliveryDateValid = setName(
	'getIsUserDefinedDeliveryDateValid',
	createSelector(getState, (state: State) => state.isUserDefinedDeliveryDateValid),
);

export const getShowPricesWithTax = setName(
	'getShowPricesWithTax',
	createSelector(getState, (state: State) => state.showPricesWithTax),
);

export const getBasicTaxMultiplier = setName(
	'getBasicTaxMultiplier',
	createSelector(
		getOrderPrice,
		(orderPrice) => foldSuccess(orderPrice)?.taxes?.basicTaxMultiplier ?? '0.24',
	),
);

export const getIsAddressValid = (type: string) =>
	setName(
		'getIsAddressValid',
		createSelector(getState, (state: State) => {
			if (type === 'shipping') return state.isShippingAddressValid;
			if (type === 'billing') return state.isBillingAddressValid;
			return false;
		}),
	);

export const getIsOrderSummaryValid = setName(
	'getIsOrderSummaryValid',
	createSelector(getState, (state: State) => state.isOrderSummaryValid),
);

export const getSubmitAddressForm = setName(
	'getSubmitAddressForm',
	createSelector(getState, (state: State) => state.submitAddressForm),
);

export const getBillingAddress = setName(
	'getBillingAddress',
	createSelector(getState, (state) => state.billingAddress),
);

export const getShippingAddress = setName(
	'getShippingAddress',
	createSelector(getState, (state) => state.shippingAddress),
);

export const getAreBillingAddressFieldsValid = (
	...fieldNames: Exclude<keyof AddressModel, 'address_id'>[]
) =>
	setName(
		'getAreBillingAddressFieldsValid',
		createSelector(getState, (state) =>
			fieldNames.every((field) => `${state.billingAddress?.[field] ?? ''}`.trim()?.length > 0),
		),
	);

export const getItemErrors = setName(
	'getItemErrors',
	createSelector(getState, (state: State) => state.itemErrors),
);

// Only return cart item errors where the item source is cart.
export const getCartItemErrors = setName(
	'getCartItemErrors',
	createSelector(
		getItemErrors,
		(itemErrors) =>
			new Map([...itemErrors].filter(([_key, error]) => error.itemSource === ItemSource.ShoppingCart)),
	),
);

export const hasItemError = (error: CartItemError) =>
	setName(
		'hasItemError',
		createSelector(getItemErrors, (itemErrors) => itemErrors.has(error.key)),
	);

//#endregion

// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Content */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get all pages
 * 
 * @param {string} locale 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} OK
 */
export function fetchPages(locale: string, options?: FetchPagesOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale
    }
  };
  return gateway.request(fetchPagesOperation, parameters);
}

/**
 * Get multiple Content in one request
 * 
 * @param {string} locale 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {string} [options.keys] Content keys, comma separated
 * @return {Promise<object>} An object with content by keys
 */
export function fetchMultipleContent(locale: string, options?: FetchMultipleContentOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale
    },
    query: {
      keys: options.keys
    }
  };
  return gateway.request(fetchMultipleContentOperation, parameters);
}

/**
 * Get Content
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.Content>} OK
 */
export function fetchContent(locale: string, key: string, options?: FetchContentOptions): Promise<api.Response<api.Content>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    }
  };
  return gateway.request(fetchContentOperation, parameters);
}

/**
 * Update or Create Content
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {module:types.Content} [options.content] 
 * @return {Promise<module:types.Content>} OK
 */
export function updateContent(locale: string, key: string, options?: UpdateContentOptions): Promise<api.Response<api.Content>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    },
    body: {
      content: options.content
    }
  };
  return gateway.request(updateContentOperation, parameters);
}

/**
 * Delete Content from database
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} Content deleted.
 */
export function deleteContent(locale: string, key: string, options?: DeleteContentOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    }
  };
  return gateway.request(deleteContentOperation, parameters);
}

/**
 * Create or update content based on provided options.
 * 
 * @param {string} key Content key
 * @param {module:types.ContentUpsertDto} contentUpsert Content to insert or update
 * @param {object} options Optional options
 * @param {boolean} [options.patch] Whether to patch the content JSON or replace it
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @param {string} [options.locale] Preferred locale, falls back to store default locale
 * @return {Promise<object>} Success
 */
export function upsertContent(key: string, contentUpsert: api.ContentUpsertDto, options?: UpsertContentOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      patch: options.patch,
      locale: options.locale
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      key
    },
    body: {
      contentUpsert
    }
  };
  return gateway.request(upsertContentOperation, parameters);
}

/**
 * Import content from a remote URL based on the settings provided.
 * 
 * @param {string} key Content key
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @param {string} [options.locale] Preferred locale, falls back to store default locale
 * @return {Promise<module:types.RemoteContentDto>} The imported content
 */
export function importContent(key: string, options?: ImportContentOptions): Promise<api.Response<api.RemoteContentDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      locale: options.locale
    },
    path: {
      key
    }
  };
  return gateway.request(importContentOperation, parameters);
}

export interface FetchPagesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface FetchMultipleContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  /**
   * Content keys, comma separated
   */
  keys?: string;
}

export interface FetchContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpdateContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  content?: api.Content;
}

export interface DeleteContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpsertContentOptions {
  /**
   * Whether to patch the content JSON or replace it
   */
  patch?: boolean;
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
  /**
   * Preferred locale, falls back to store default locale
   */
  locale?: string;
}

export interface ImportContentOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
  /**
   * Preferred locale, falls back to store default locale
   */
  locale?: string;
}

const fetchPagesOperation: api.OperationInfo = {
  path: '/content/{locale}/pages',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fetchMultipleContentOperation: api.OperationInfo = {
  path: '/content/{locale}/multiple',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fetchContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const deleteContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const upsertContentOperation: api.OperationInfo = {
  path: '/v2/content/upsert/{key}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const importContentOperation: api.OperationInfo = {
  path: '/v2/content/import/{key}',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

import { SelectedOptions } from '../reducers/product.reducer';
import { Injectable } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { queuePreflight } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { StorePersistService } from '@app/checkout/modules/checkout-shared/services/store-persist.service';
import { PreflightOptionsInput } from '@app/file-api/file-api.service';
import { StoreDataService } from '@app/shared/services/store-data.service';
import { getDebug, isPlainObjectEqual, pick } from '@app/shared/utils/util';

/** Product preflight service for validating and queueing preflight checks. */
@Injectable({ providedIn: 'root' })
export class ProductPreflightService {
	private readonly debug = getDebug('ProductPreflightService');

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly persist: StorePersistService,
		private readonly storeData: StoreDataService,
	) {}

	/**
	 * Check if the printfile is valid (whether it has a key and a filename).
	 * @param printfile The printfile to check.
	 * @returns True if the printfile is valid, false otherwise.
	 */
	isValidPrintfile(printfile: api.PrintfileDto | undefined): printfile is api.PrintfileDto {
		return !!printfile?.key && !!printfile?.filename;
	}

	/**
	 * Get preflight parameters from product metadata and selected options.
	 * @param product The product.
	 * @param selectedOptions Product options selected by the user.
	 * @param price Product price (for width & height information). Optional due to editor files that won't be preflighted.
	 * @returns Preflight parameters or undefined if profile is missing.
	 */
	getPreflightParameters(
		product: api.ProductDto | api.ProductDetailsDto,
		selectedOptions: SelectedOptions,
		price?: Pick<api.ProductPriceDto, 'size'>,
	): PreflightOptionsInput | undefined {
		const requiredParameters = [
			'profile',
			'scope',
			'color',
			'width',
			'height',
			'precision',
			'errorResolution',
			'warningResolution',
		] satisfies (keyof PreflightOptionsInput)[];

		const parameters = pick(
			{ ...product.metadata, ...selectedOptions, ...price?.size },
			requiredParameters,
		);
		// Return undefined as no preflight is possible if profile is missing.
		return parameters.profile ? (parameters as PreflightOptionsInput) : undefined;
	}

	/**
	 * Check if the preflight should be queued.
	 * @param printfile The printfile to check.
	 * @param hasNewPrintfile Flag indicating if the user has uploaded a new printfile.
	 * @param selectedOptions Product options selected by the user.
	 * @param initialOptions Initial options of the product.
	 * @returns True if the preflight should be queued, false otherwise.
	 */
	shouldQueuePreflight(
		printfile: api.PrintfileDto | undefined,
		hasNewPrintfile: boolean,
		selectedOptions: SelectedOptions = {},
		initialOptions: SelectedOptions = {},
	): printfile is api.PrintfileDto {
		// istanbul ignore if -- TODO: remove feature flag.
		if (this.persist.getFlag('enableNewFileInfra')) return false;
		const hasValidPrintfile = this.isValidPrintfile(printfile);
		const hasChanged = !isPlainObjectEqual(initialOptions, selectedOptions);
		const shouldQueue = hasValidPrintfile && (hasNewPrintfile || hasChanged);
		// Note: We must queue the preflight even if the printfile is still processing.
		// It's not an issue because the preflight would not be queued anyway unless either
		// the printfile or the selected options have changed - and in those cases we should
		// restart the preflight even if the file is processing, to get an up-to-date report.
		this.debug.extend('should-queue-preflight')(shouldQueue, {
			printfile,
			hasValidPrintfile,
			hasNewPrintfile,
			hasChanged,
		});
		return shouldQueue;
	}

	/**
	 * Queues a preflight check for a cart item.
	 * @param itemId - The ID of the cart item.
	 * @param product - The product.
	 * @param printfile - The printfile.
	 * @param selectedOptions - The product options selected by the user.
	 * @param price - Product price (for width and height).
	 */
	queuePreflight(
		itemId: string,
		product: api.ProductDto | api.ProductDetailsDto,
		printfile: api.PrintfileDto,
		selectedOptions: SelectedOptions = {},
		price?: Pick<api.ProductPriceDto, 'size'>,
	): void {
		// We need to "queue" a preflight for editor files as well, because the editor state.
		// But apparently sometimes it's possible for the size data to not be available
		if (product.metadata?.profile && !price?.size) {
			throw new Error('queuePreflight: price.size is expected to be present for uploaded files!');
		}
		this.store.dispatch(
			queuePreflight(printfile.key, {
				body: {
					// API returns null if product_id is null in the database but sending
					// null product_id to the same endpoint in request body will result in a validation error
					product_id: printfile.product_id ?? product.id ?? undefined,
					key: printfile.key,
					profile: product?.metadata?.profile,
					editor_state_id: printfile.editor_state_id,
					filename: printfile.filename,
					options: this.getPreflightParameters(product, selectedOptions, price),
					item_id: itemId,
					store_id: this.storeData.storeData.id,
				},
			}),
		);
	}
}

// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module CustomersCanvas */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Performs the editor printfile upload
 * 
 * @param {module:types.EditorFileUploadInfoDto} editorFileUploadInfo Editor printfile information
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.PrintfileDto>} Success
 */
export function uploadEditorFile(editorFileUploadInfo: api.EditorFileUploadInfoDto, options?: UploadEditorFileOptions): Promise<api.Response<api.PrintfileDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      editorFileUploadInfo
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(uploadEditorFileOperation, parameters);
}

/**
 * Call Customer's Canvas API to generate proof images from a state id
 * 
 * @param {module:types.GenerateProofImagesParameters} GenerateProofImagesParameters Editor printfile information
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ProofImageDto>} Proof images ready to be displayed
 */
export function generateProofImages(GenerateProofImagesParameters: api.GenerateProofImagesParameters, options?: GenerateProofImagesOptions): Promise<api.Response<api.ProofImageDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      GenerateProofImagesParameters
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(generateProofImagesOperation, parameters);
}

/**
 * Checks if user state file exists or not
 * 
 * @param {string} userId 
 * @param {string} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.StateFileStatusDto>} State file status
 */
export function getStateFileStatus(userId: string, id: string, options?: GetStateFileStatusOptions): Promise<api.Response<api.StateFileStatusDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      userId,
      id
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getStateFileStatusOperation, parameters);
}

/**
 * Trigger an editor font update
 */
export function updateEditorFonts(): Promise<api.Response<api.SuccessDto>> {
  return gateway.request(updateEditorFontsOperation);
}

/**
 * Get a token to authenticate to the product editor to upload assets etc.
 */
export function authenticate(): Promise<api.Response<api.TokenResponseDto>> {
  return gateway.request(authenticateOperation);
}

/**
 * Get a token to authenticate to the product editor to upload assets etc.
 * 
 * @param {module:types.AnonUserInputDto} anonUserInput 
 * @return {Promise<module:types.TokenResponseDto>} Editor authentication tokens
 */
export function authenticateAnonymous(anonUserInput: api.AnonUserInputDto): Promise<api.Response<api.TokenResponseDto>> {
  const parameters: api.OperationParamGroups = {
    body: {
      anonUserInput
    }
  };
  return gateway.request(authenticateAnonymousOperation, parameters);
}

/**
 * Authorize an anonymous user to be merged with an authenticated user
 * 
 * @param {string} anonUserId The anonymous Customer's Canvas user's id
 * @return {Promise<module:types.SuccessDto>} Success status
 */
export function authorizeAnonymousUserMerge(anonUserId: string): Promise<api.Response<api.SuccessDto>> {
  const parameters: api.OperationParamGroups = {
    path: {
      anonUserId
    }
  };
  return gateway.request(authorizeAnonymousUserMergeOperation, parameters);
}

/**
 * Merge two users' editor data. The source user must be an anonymous user.
 * 
 * @param {string} anonUserId The anonymous Customer's Canvas user's id
 * @return {Promise<module:types.SuccessDto>} Success status
 */
export function mergeAnonymousUser(anonUserId: string): Promise<api.Response<api.SuccessDto>> {
  const parameters: api.OperationParamGroups = {
    path: {
      anonUserId
    }
  };
  return gateway.request(mergeAnonymousUserOperation, parameters);
}

/**
 * Merge two users' editor data
 * 
 * @param {module:types.MergeUsersInputDto} mergeUsersInput 
 * @return {Promise<module:types.SuccessDto>} Success status
 */
export function mergeUsers(mergeUsersInput: api.MergeUsersInputDto): Promise<api.Response<api.SuccessDto>> {
  const parameters: api.OperationParamGroups = {
    body: {
      mergeUsersInput
    }
  };
  return gateway.request(mergeUsersOperation, parameters);
}

export interface UploadEditorFileOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GenerateProofImagesOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GetStateFileStatusOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const uploadEditorFileOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/upload-editor-file',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const generateProofImagesOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/generate-proof-images',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getStateFileStatusOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/state-file-status/{userId}/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateEditorFontsOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/update-editor-fonts',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const authenticateOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/authenticate',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const authenticateAnonymousOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/authenticate-anonymous',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const authorizeAnonymousUserMergeOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/authorize-anon-user-merge/{anonUserId}',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const mergeAnonymousUserOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/merge-anonymous-user/{anonUserId}',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const mergeUsersOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/merge-users',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

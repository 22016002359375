// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Content */
// Auto-generated, edits will be overwritten
import * as Content from '../Content';

export const FETCH_PAGES_START = 's/Content/FETCH_PAGES_START';
export const FETCH_PAGES = 's/Content/FETCH_PAGES';
export type FETCH_PAGES = any;

export function fetchPages(locale: string, options?: Content.FetchPagesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: FETCH_PAGES_START, meta: { info, params: { locale, options } } });
    return Content.fetchPages(locale, options)
      .then(response => dispatch({
        type: FETCH_PAGES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const FETCH_MULTIPLE_CONTENT_START = 's/Content/FETCH_MULTIPLE_CONTENT_START';
export const FETCH_MULTIPLE_CONTENT = 's/Content/FETCH_MULTIPLE_CONTENT';
export type FETCH_MULTIPLE_CONTENT = any;

export function fetchMultipleContent(locale: string, options?: Content.FetchMultipleContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: FETCH_MULTIPLE_CONTENT_START, meta: { info, params: { locale, options } } });
    return Content.fetchMultipleContent(locale, options)
      .then(response => dispatch({
        type: FETCH_MULTIPLE_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const FETCH_CONTENT_START = 's/Content/FETCH_CONTENT_START';
export const FETCH_CONTENT = 's/Content/FETCH_CONTENT';
export type FETCH_CONTENT = api.Content;

export function fetchContent(locale: string, key: string, options?: Content.FetchContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: FETCH_CONTENT_START, meta: { info, params: { locale, key, options } } });
    return Content.fetchContent(locale, key, options)
      .then(response => dispatch({
        type: FETCH_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_CONTENT_START = 's/Content/UPDATE_CONTENT_START';
export const UPDATE_CONTENT = 's/Content/UPDATE_CONTENT';
export type UPDATE_CONTENT = api.Content;

export function updateContent(locale: string, key: string, options?: Content.UpdateContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_CONTENT_START, meta: { info, params: { locale, key, options } } });
    return Content.updateContent(locale, key, options)
      .then(response => dispatch({
        type: UPDATE_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DELETE_CONTENT_START = 's/Content/DELETE_CONTENT_START';
export const DELETE_CONTENT = 's/Content/DELETE_CONTENT';
export type DELETE_CONTENT = undefined;

export function deleteContent(locale: string, key: string, options?: Content.DeleteContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DELETE_CONTENT_START, meta: { info, params: { locale, key, options } } });
    return Content.deleteContent(locale, key, options)
      .then(response => dispatch({
        type: DELETE_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPSERT_CONTENT_START = 's/Content/UPSERT_CONTENT_START';
export const UPSERT_CONTENT = 's/Content/UPSERT_CONTENT';
export type UPSERT_CONTENT = any;

export function upsertContent(key: string, contentUpsert: api.ContentUpsertDto, options?: Content.UpsertContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPSERT_CONTENT_START, meta: { info, params: { key, contentUpsert, options } } });
    return Content.upsertContent(key, contentUpsert, options)
      .then(response => dispatch({
        type: UPSERT_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const IMPORT_CONTENT_START = 's/Content/IMPORT_CONTENT_START';
export const IMPORT_CONTENT = 's/Content/IMPORT_CONTENT';
export type IMPORT_CONTENT = api.RemoteContentDto;

export function importContent(key: string, options?: Content.ImportContentOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: IMPORT_CONTENT_START, meta: { info, params: { key, options } } });
    return Content.importContent(key, options)
      .then(response => dispatch({
        type: IMPORT_CONTENT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

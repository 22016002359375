// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Cxml */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get the necessary cXML document and endpoint to post the cXML PunchOutOrderMessage
 * 
 * @param {module:types.CxmlCartDto} cxmlCart 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.CxmlMessageDto>} The cXML payload and endpoint to post it to
 */
export function getOrderMessage(cxmlCart: api.CxmlCartDto, options?: GetOrderMessageOptions): Promise<api.Response<api.CxmlMessageDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      cxmlCart
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getOrderMessageOperation, parameters);
}

/**
 * Get the cXML punch out carts to be able to complete POs manually
 * 
 * @param {object} options Optional options
 * @param {number} [options.limit] 
 * @param {number} [options.search] cXML session id
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ProductListWithUserDto[]>} The list of cXML punch out carts
 */
export function listCarts(options?: ListCartsOptions): Promise<api.Response<api.ProductListWithUserDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      limit: options.limit,
      search: options.search
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(listCartsOperation, parameters);
}

/**
 * Get the cXML punch out cart to be able to complete the PO manually
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ProductListWithProductsDto>} The cXML punch out cart
 */
export function getCart(id: number, options?: GetCartOptions): Promise<api.Response<api.ProductListWithProductsDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getCartOperation, parameters);
}

/**
 * Get the cXML punch out session details
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.CxmlSessionDto>} The cXML punch out session
 */
export function getSession(id: number, options?: GetSessionOptions): Promise<api.Response<api.CxmlSessionDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getSessionOperation, parameters);
}

/**
 * This route handles receiving cXML messages and responding to them.
 * For supported messages, see http://xml.cxml.org/current/cXMLReferenceGuide.pdf sections:
 *  * 4.3.2 PunchOutSetupRequest
 *  * 6.2 OrderRequest Documents
 * 
 * @param {string} message The cXML message as XML. For example a PunchOutSetupRequest looks like this:
 *   
 *   ```xml
 *   <?xml version="1.0" encoding="UTF-8"?>
 *   <!DOCTYPE cXML SYSTEM "http://xml.cxml.org/schemas/cXML/1.2.014/cXML.dtd">
 *   <!--
 *     payloadID: must be unique for each different cXML document
 *     timestamp: when the document was created
 *    -->
 *   <cXML xml:lang="en-US" payloadID="e0f9b274-bd2f-4ea7-9ee3-a75803c469db" timestamp="2020-04-05T13:23:00+02:00">
 *     <Header>
 *       <From>
 *         <Credential domain="VAT">
 *           <!-- Customer's VAT ID (y-tunnus) -->
 *           <Identity>FI123456789</Identity>
 *         </Credential>
 *       </From>
 *       <To>
 *         <Credential domain="VAT">
 *           <!-- Grano's VAT ID (y-tunnus) -->
 *           <Identity>FI21979350</Identity>
 *         </Credential>
 *       </To>
 *       <Sender>
 *         <Credential domain="VAT">
 *           <!-- Customer's VAT ID (y-tunnus) -->
 *           <Identity>FI123456789</Identity>
 *           <!-- Chosen when punch out is configured -->
 *           <SharedSecret>rZy6KDc39Cye</SharedSecret>
 *         </Credential>
 *         <!-- Information about the system used -->
 *         <UserAgent>cXML Provider 1.0</UserAgent>
 *       </Sender>
 *     </Header>
 *     <Request>
 *       <PunchOutSetupRequest operation="create">
 *         <!-- Unique identifier that is sent back unchanged in the punch out order message -->
 *         <BuyerCookie>9af2d9442f036ckljl1913618e59742f6</BuyerCookie>
 *         <!-- Strandard Contact element should be used. -->
 *         <!-- <Extrinsic name="User">Matti.Meikalainen@example.com</Extrinsic>
 *         <Extrinsic name="FirstName">Matti</Extrinsic>
 *         <Extrinsic name="LastName">Meikäläinen</Extrinsic>
 *         <Extrinsic name="Phone">+358123456789</Extrinsic> -->
 *         <!-- Preferred locale (fi, en, sv), defaults to store's default locale -->
 *         <!-- <Extrinsic name="Locale">fi</Extrinsic> -->
 *         <!-- The URL where the punch out order message will be sent at the end of the session -->
 *         <BrowserFormPost>
 *           <URL>https://httpbin.org/post</URL>
 *         </BrowserFormPost>
 *         <!-- Contact should be sent if possible to identify punch out users -->
 *         <Contact role="endUser">
 *           <Name xml:lang="en-US">Matti Meikäläinen</Name>
 *           <Email>Matti.Meikalainen@example.com</Email>
 *           <Phone name="work">
 *             <TelephoneNumber>
 *               <CountryCode isoCountryCode="FI">358</CountryCode>
 *               <AreaOrCityCode></AreaOrCityCode>
 *               <Number>123456789</Number>
 *             </TelephoneNumber>
 *           </Phone>
 *         </Contact>
 *         <!-- My Grano Order Channel API address for receiving cXML messages -->
 *         <SupplierSetup>
 *           <URL>https://api.granoshop.fi/cxml/punchout</URL>
 *         </SupplierSetup>
 *       </PunchOutSetupRequest>
 *     </Request>
 *   </cXML>
 *   
 *   ```
 * @return {Promise<string>} The operation succeeded, example response:
 *   
 *   ```xml
 *   <!DOCTYPE cXML SYSTEM "http://xml.cxml.org/schemas/cXML/1.2.014/cXML.dtd">
 *   <cXML payloadID="958074700772" timestamp="2019-03-14T12:59:09-07:00">
 *   	<Response>
 *   		<Status code="200" text="success"/>
 *   		<PunchOutSetupResponse>
 *   			<StartPage>
 *   				<URL>https://store.example.com/punchout?t=secretToken</URL>
 *   			</StartPage>
 *   		</PunchOutSetupResponse>
 *   	</Response>
 *   </cXML>
 *   ```
 */
export function punchOut(message: string): Promise<api.Response<string>> {
  const parameters: api.OperationParamGroups = {
    body: {
      message
    }
  };
  return gateway.request(punchOutOperation, parameters);
}

/**
 * Generates and processes a PunchOut setup request for testing
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<string>} A PunchOut setup response
 */
export function startPunchoutTest(options?: StartPunchoutTestOptions): Promise<api.Response<string>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(startPunchoutTestOperation, parameters);
}

/**
 * Logs a PunchOut order message and returns a self-close page
 * 
 * @param {module:types.CxmlFormDataDto} cxmlFormData A PunchOut order message as a base64 encoded string
 * @return {Promise<string>} Self-closing web page
 */
export function finishPunchoutTest(cxmlFormData: api.CxmlFormDataDto): Promise<api.Response<string>> {
  const parameters: api.OperationParamGroups = {
    body: {
      cxmlFormData
    }
  };
  return gateway.request(finishPunchoutTestOperation, parameters);
}

/**
 * Creates a valid PunchOut Order Request
 * 
 * @param {module:types.CxmlOrderRequestMockFormDataDto} cxmlOrderRequestMockFormData A PunchOut order message as a base64 encoded string
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<string>} cXML PunchOut Order Request
 */
export function createOrderRequestMock(cxmlOrderRequestMockFormData: api.CxmlOrderRequestMockFormDataDto, options?: CreateOrderRequestMockOptions): Promise<api.Response<string>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      cxmlOrderRequestMockFormData
    }
  };
  return gateway.request(createOrderRequestMockOperation, parameters);
}

export interface GetOrderMessageOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface ListCartsOptions {
  limit?: number;
  /**
   * cXML session id
   */
  search?: number;
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GetCartOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GetSessionOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface StartPunchoutTestOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface CreateOrderRequestMockOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const getOrderMessageOperation: api.OperationInfo = {
  path: '/v2/cxml/order-message',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const listCartsOperation: api.OperationInfo = {
  path: '/v2/cxml/carts',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getCartOperation: api.OperationInfo = {
  path: '/v2/cxml/cart/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getSessionOperation: api.OperationInfo = {
  path: '/v2/cxml/session/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const punchOutOperation: api.OperationInfo = {
  path: '/v2/cxml/punchout',
  contentTypes: ['application/xml'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const startPunchoutTestOperation: api.OperationInfo = {
  path: '/v2/cxml/punchout/test',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const finishPunchoutTestOperation: api.OperationInfo = {
  path: '/v2/cxml/punchout/test/finish',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOrderRequestMockOperation: api.OperationInfo = {
  path: '/v2/cxml/punchout/test/order-request',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

import { CartItem, ShippingPrice } from '../reducers/checkout.reducer';
import { ProductFlowPage } from '@app/product/reducers/product-flow.reducer';
import { ProductType } from '@app/product/reducers/product.reducer';
import { isDownloadableProduct } from '@app/product/utils/product-utils';
import type { FileItem } from '@app/upload/upload.component';

export type CartItemFile = api.PrintfileDto | FileItem;

export interface ParsedShippingMethod {
	slug: string;
	type: api.ShippingMethod['type'];
	name: string;
	description: string;
	isValid: boolean;
	isDefault: boolean;
	price?: ShippingPrice;
	formattedPrice?: string;
	invalidProductNames?: Set<string>;
	allowAllCountries?: boolean;
	allowedCountries?: string[];
	deliveryTimeMin?: number;
	deliveryTimeMax?: number;
	isUserDefinable?: boolean;
	periodicDeliveryMode?: string;
}

/** Get the printfile for a product. */
export function getCartItemFile(item: CartItem): CartItemFile | undefined {
	switch (item?.product?.type) {
		case ProductType.Editable:
		case ProductType.Variable:
			return item?.options?.printfile;
		case ProductType.MultipleOption:
			return getMultipleOptionFile(item);
		default:
			return;
	}
}

/** Get the printfile for a multiple option product. */
export function getMultipleOptionFile(item: CartItem): CartItemFile | undefined {
	const workflow = item?.productFlowData?.selectedWorkflow?.key;
	switch (workflow) {
		case ProductFlowPage.Upload:
		case ProductFlowPage.ProductDesigner:
			return item?.options?.printfile;
		case ProductFlowPage.DesignService:
			return item?.productFlowData?.designService?.files ?? [];
		default:
			return; // Workflow does not require file(s)
	}
}

/** See if a product requires a printfile. */
export function isPrintfileRequired(item: CartItem): boolean {
	const productType = item?.product?.type as ProductType;
	if ([ProductType.Editable, ProductType.Variable].includes(productType)) return true;
	if (productType === ProductType.MultipleOption) {
		const workflow = item?.productFlowData?.selectedWorkflow?.key;
		if (!workflow) return false;
		return [ProductFlowPage.Upload, ProductFlowPage.ProductDesigner].includes(
			workflow as ProductFlowPage,
		);
	}
	return false;
}

/** See if a product is a designer product. */
export function isDesignerProduct(item: CartItem): boolean {
	const productType = item?.product?.type;
	if (productType === ProductType.Editable) return true;
	if (productType === ProductType.MultipleOption) {
		const stepKeys = new Set([
			item?.productFlowData?.selectedWorkflow?.key,
			...(item?.productFlowData?.steps ?? []),
		]);
		return stepKeys.has(ProductFlowPage.ProductDesigner);
	}
	return false;
}

/** See if a product's printfile has expired or not. */
export function isPrintfileAvailable(item: CartItem): boolean {
	if (!isPrintfileRequired(item)) return true;
	if (!item?.options?.printfile?.key) return false;
	const expiration = item?.options?.printfile?.expires_at as string;
	if (!expiration) return item.isProcessing !== false;
	return Date.now() < new Date(expiration).valueOf();
}

/** Check if product can be checked out. */
export function isCheckoutAllowed(item: CartItem): boolean {
	const product = item.product;
	// Not allowed if missing locale or metadata.
	if (!product?.locale || !product?.metadata) return false;
	// Not allowed if product is missing a printfile.
	if (!isPrintfileAvailable(item)) return false;
	// Not allowed if design is not validated.
	if (isDesignerProduct(item) && !item?.productFlowData?.isDesignValidated) return false;
	// Not allowed if configured so in product metadata.
	return product?.metadata?.allow_checkout ?? true;
}

/**
 * Checks if all items in the cart are downloadable products.
 * @param items The list of cart items to check.
 */
export function checkIfOnlyDownloadables(items: api.CartItemWithProductDto[]): boolean {
	return (
		items.length > 0 &&
		items.every((item) => isDownloadableProduct(item?.product?.metadata?.order_integration ?? []))
	);
}
